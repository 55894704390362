export const createTwitterTweet = (text: string) => {
    return `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}`;
};

export const createLinkedInShareLink = (referer: string, text?: string) => {
    const url = `https://www.linkedin.com/sharing/share-offsite/?url=${referer}`;

    if (text) {
        return `${url}&text=${encodeURIComponent(text)}`;
    }

    return url;
};

export const createMailToLink = (to: Array<string> = [], subject: string, body: string) => {
    return `mailto:${to.join(',')}?subject=${subject}&body=${body}`;
};

export const createGmailLink = (to: Array<string> = [], subject: string, body: string) => {
    return `https://mail.google.com/mail/u/0?view=cm&to=${to.join(',')}&su=${subject}&body=${body}`;
};
