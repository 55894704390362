import { useState } from 'react';

import { Heading } from '@/components/dom/text-elements';
import BaseModal from '@/components/global/base-modal';
import Button from '@/components/global/button';
import Icon from '@/components/global/icon';
import { createGmailLink, createLinkedInShareLink, createMailToLink, createTwitterTweet } from '@/helpers/socials';
import { SITE_BASE_URL } from '@/lib/constants';
import * as gtag from '@/lib/gtag';

export type ShareModalProps = {
    isOpen: boolean;
    link: string;
    embed?: string;
    closeFn: () => void;
    twitterShareString: string;
    gtagCategory: string;
};

type CopyButtonProps = {
    onClick: () => void;
};
const CopyButton = ({ onClick }: CopyButtonProps) => {
    const [copied, setCopied] = useState(false);
    return (
        <Button
            className="text-s w-20 !text-brand-blue-nu"
            type="action"
            shadow={false}
            onClick={() => {
                onClick();
                setCopied(true);
            }}
            color="white"
        >
            {copied ? (
                <div className="flex items-center text-brand-blue-nu">
                    <Icon
                        type="check"
                        size="sm"
                        color="white"
                        className="mr-2 bg-brand-blue-nu p-1 pt-[5px] rounded-full"
                    />
                    Copied!
                </div>
            ) : (
                <div className="flex items-center text-brand-blue-yves">
                    <Icon
                        type="copy"
                        color="blueYves"
                        className="mr-2"
                    />
                    Copy
                </div>
            )}
        </Button>
    );
};

const ShareModal = ({ closeFn, link, embed, isOpen, twitterShareString, gtagCategory }: ShareModalProps) => {
    const embedSnippet = `<iframe width="100%" height="480" src="${embed}" frameborder="0"></iframe>`;
    const fullLink = `${SITE_BASE_URL}${link}`;

    const closeFnOverride = () => {
        closeFn();
    };

    const copyLink = () => {
        if (typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(link);
            gtag.event({
                action: 'Copy',
                category: gtagCategory,
                label: 'Direct Link',
            });
        }
    };

    const copyEmbed = () => {
        if (typeof window !== 'undefined') {
            window.navigator.clipboard.writeText(embedSnippet);
            gtag.event({
                action: 'Copy',
                category: gtagCategory,
                label: 'Direct Link',
            });
        }
    };

    const handleShare = (label: string, shareLink: string) => {
        return () => {
            if (typeof window !== 'undefined') {
                gtag.event({
                    action: 'Share',
                    category: gtagCategory,
                    label,
                });
                window.open(shareLink, '_blank');
            }
        };
    };

    return (
        <BaseModal
            header="Share"
            isOpen={isOpen}
            onRequestClose={closeFnOverride}
        >
            <div className="py-6 px-2">
                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Direct link
                </Heading>
                <div className="flex gap-2 mb-4">
                    <p className="bg-brand-gray p-3 w-full max-width rounded-lg border border-analyst-lavender">
                        {fullLink}
                    </p>
                    <CopyButton onClick={copyLink} />
                </div>

                <hr className="mb-4" />

                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Social media
                </Heading>
                <div className="flex mb-4">
                    <Button
                        type="action"
                        onClick={handleShare('Twitter', createTwitterTweet(`${twitterShareString}\n${fullLink}`))}
                        color="primary"
                        className="px-1.5 pt-3 mr-2 rounded-sm h-7"
                    >
                        <Icon
                            type="twitter"
                            color="white"
                        />
                    </Button>

                    <Button
                        type="action"
                        onClick={handleShare(
                            'LinkedIn',
                            createLinkedInShareLink(fullLink, `Check out this index on Thematic: ${fullLink}`)
                        )}
                        color="primary"
                        className="px-1.5 pt-2 mr-2 rounded-sm h-7"
                    >
                        <Icon
                            type="linkedIn"
                            color="white"
                        />
                    </Button>
                </div>

                <hr className="mb-4" />

                <Heading
                    importance={5}
                    className="text-analyst-lighter-black mb-4"
                >
                    Email
                </Heading>
                <div className="flex gap-4 mb-4">
                    <Button
                        type="action"
                        onClick={handleShare(
                            'Email',
                            createMailToLink(
                                undefined,
                                'Check out this Thematic index',
                                `Check out this index on Thematic: ${fullLink}`
                            )
                        )}
                        color="analyst-purple"
                        roundedCorners="lg"
                    >
                        <Icon
                            type="email"
                            color="white"
                            className="mr-2"
                        />
                        Email
                    </Button>
                    <Button
                        type="action"
                        onClick={handleShare(
                            'Gmail',
                            createGmailLink(
                                undefined,
                                'Check out this Thematic index',
                                `Check out this index on Thematic: ${fullLink}`
                            )
                        )}
                        color="analyst-purple"
                        roundedCorners="lg"
                    >
                        <Icon
                            type="gmail"
                            color="white"
                            className="mr-2"
                        />
                        Gmail
                    </Button>
                </div>
                {embed && (
                    <>
                        <hr className="mb-4" />

                        <Heading
                            importance={4}
                            className="text-brand-blue mb-4"
                        >
                            Embed graph
                        </Heading>
                        <div className="flex mb-4">
                            <p className="bg-brand-gray mr-4 p-2 w-full max-width">{embedSnippet}</p>
                            <CopyButton onClick={copyEmbed} />
                        </div>
                    </>
                )}
            </div>
        </BaseModal>
    );
};
export default ShareModal;
